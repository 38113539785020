<template>
  <div class="left card-header">
    <img class="icon" :src="icon" />
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    }
  }
};
</script>

<style lang="less" scoped>
.card-header {
  border-bottom: 1px solid #f5f5f5;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  .icon {
    margin-right: 8px;
  }
  .title {
    font-size: 14px;
    color: #1890ff;
    font-weight: bold;
  }
}
</style>